<template>
  <v-sheet class="advance" id="advance" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col class="my-auto">
        <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
      </v-col>
      <v-col class="text-right">
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateOrCreate"
        >
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Cancel
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-card outlined flat>
          <v-card-text>
            <v-col md="10">
              <v-form
                ref="advanceForm"
                v-model.trim="formValid"
                lazy-validation
                v-on:submit.stop.prevent="updateOrCreate"
              >
                <v-container fluid>
                  <v-row>
                    <v-col md="3" class="my-auto py-0">
                      <label for="first-name" class="btx-label mt-2 required">Employee</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <SelectInput
                        hide-details
                        v-model="advance.employee"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :rules="[vrules.required(advance.employee, 'First Name')]"
                        :class="{ required: !advance.employee }"
                        :items.sync="genderList"
                        id="gender"
                        placeholder="Employee"
                      >
                      </SelectInput>
                    </v-col>

                    <v-col md="3" class="my-auto py-0">
                      <label for="first-name" class="btx-label mt-2 required">Department</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <SelectInput
                        hide-details
                        v-model="advance.employee"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :rules="[vrules.required(advance.employee, 'First Name')]"
                        :class="{ required: !advance.employee }"
                        :items.sync="genderList"
                        id="gender"
                        placeholder="Department"
                      >
                      </SelectInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="date-of-start" class="btx-label mt-2 required"
                        >Advance Date</label
                      >
                    </v-col>
                    <v-col md="3" class="py-0">
                      <DatePicker
                        hide-details
                        v-model="advance.date_of_start"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="date-of-start"
                        placeholder="Advance Date"
                      >
                      </DatePicker>
                    </v-col>
                    <v-col md="3" class="text-right my-auto py-0">
                      <label for="date-of-start" class="btx-label mt-2">Amount (RM)</label>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <TextInput
                        hide-details
                        text="number"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="address-line-2"
                        v-model="advance.duration_time"
                        :rules="[vrules.required(advance.duration_time, 'First Name')]"
                        :class="{ required: !advance.duration_time }"
                        placeholder="Duration"
                      >
                      </TextInput>
                    </v-col>

                    <v-col md="3" class="my-auto py-0">
                      <label for="date-of-start" class="btx-label mt-2 required">Added On</label>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <DatePicker
                        hide-details
                        v-model="advance.date_of_start"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="date-of-start"
                        placeholder="Added On"
                      >
                      </DatePicker>
                    </v-col>
                    <v-col md="3" class="text-right py-0"></v-col>
                    <v-col md="3" class="text-right py-0"></v-col>

                    <v-col md="3" class="py-0">
                      <label for="reason" class="btx-label mt-2">Reason</label>
                    </v-col>
                    <v-col md="9" class="">
                      <TextAreaInput
                        v-model="message"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        placeholder="Reason"
                        :counter="250"
                        :rows="7"
                        :cols="30"
                      >
                      </TextAreaInput>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER, GET_CUSTOMER } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  name: "leave-create",
  title: "Create Advance",
  data() {
    return {
      pageLoading: false,
      formValid: true,
      advanceId: null,
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],
      genderList: [
        {
          text: "ALAN HENG",
          value: "ALAN HENG",
        },
        {
          text: "Krishan",
          value: "krishan",
        },
        {
          text: "Radhika",
          value: "radhika",
        },
      ],
      advance: {
        title: null,
        employee: null,
        last_name: null,
        gender: null,
        paidUnpaid: null,
        leaveday: null,
        date_of_start: null,
        date_of_end: null,
        phone_number: null,
        address_line_1: null,
        duration_time: null,
        unit_number: null,
        postal_code: null,
        country: null,
      },
    };
  },
  components: {
    TextInput,
    SelectInput,
    DatePicker,
    TextAreaInput,
  },
  methods: {
    pageTitle() {
      if (this.advanceId) {
        return "Update Advance";
      }
      return "Update Advance";
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.advanceForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.advanceId) {
          const advance = await UPDATE_CUSTOMER(_this.advanceId, _this.advance);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: advance.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Advance Updated Successfully." },
          ]);
        } else {
          const advance = await CREATE_CUSTOMER(_this.advance);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: advance.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Advance Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getAdvance() {
      const advance = await GET_CUSTOMER(this.advanceId);
      this.advance = {
        title: advance.title,
        employee: advance.employee,
        last_name: advance.last_name,
        paidUnpaid: advance.paidUnpaid,
        leaveday: advance.leaveday,
        gender: advance.gender,
        phone_number: advance.phone_number,
        date_of_start: advance.date_of_start,
        date_of_end: advance.date_of_end,
        address_line_1: advance.address_line_1,
        duration_time: advance.duration_time,
        unit_number: advance.unit_number,
        postal_code: advance.postal_code,
        country: advance.country,
      };
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Advance", disabled: true },
        { text: "Update", disabled: true },
        { text: advance.barcode, disabled: true },
      ]);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Advance", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "customer-update") {
      const { id } = params;
      if (id) {
        this.advanceId = toSafeInteger(id);
        this.getAdvance();
      } else {
        this.goBack();
      }
    }
  },
};
</script>
